
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import AddTagsModal from "@/components/modals/forms/AddTagsModal.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import JwtService from "@/core/services/JwtService";
import { active } from "@/core/data/genericData";

export default defineComponent({
  name: "add-product-modal",
  directives: { mask },
  props: {
    id: { required: true },
    // data: {
    //     type: Object
    // }
  },
  components: {
    AddTagsModal,
  },
  setup(props) {
    var ids = ref(props.id);
    const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const treeRef = ref<null | HTMLFormElement>(null);
    const addCategoryAttrSettingModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const loadingHsn = ref<boolean>(false);
    const router = useRouter();
    const uom_data = ref([]);
    const shape = ref([]);
    const level = ref([
      {
        id: 0,
        name: "Level 1",
      },
      {
        id: 1,
        name: "Level 2",
      },
      {
        id: 2,
        name: "Level 3",
      },
      {
        id: 3,
        name: "Level 4",
      },
    ]);
    const selection = ref([
      {
        id: 0,
        name: "No",
      },
      {
        id: 1,
        name: "Yes",
      },
    ]);

    const valueTypes = ref([
      {
        id: "RANGE",
        name: "Range",
      },
      {
        id: "TEXT",
        name: "Text",
      },
      {
        id: "NUMBER",
        name: "Number",
      },
      {
        id: "TABLE",
        name: "Table",
      },
    ]);

    const newTreeValue = ref<any>([]);
    const newTreeNode = ref<any>({});
    const categoryTree = ref([]);
    const parentAttributeList = ref<any>([]);
    const treeProps = ref({
      children: "sub_attributes",
      label: "category_name",
    });

    onMounted(async () => {
      //const db_data = {}
      // await setShapedata(shape.value);
      // await setTagData("");
      // await setIndustrydata(industry.value);
      // await setFormdata(form.value);
      // await setStagedata(stage.value);
      // await setLengthdata(length.value);
      // await setParentData("");
      debugger;
      await getCategoryTree();
      //await getParentAttrList();
      //await getUOM();
      await setHsnData("");
      //await setInputTabledata();
      await setInputTextdata();
      await setNextDisplayOrder();
      console.log('formData.value')
console.log(formData.value)
    });

    const UploadImage = async (category_id) => {
      const db_data = {
        category_id: category_id,
        file_name: rawImg.value.file_name,
        file_data: rawImg.value.file_data,
        user_id: 1,
      };

      await store
        .dispatch(Actions.CUST_ADD_CAT_IMAGE, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: "Uploaded successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addCategoryAttrSettingModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    let rawImg = ref();
    function encodeImagetoBase64L(element) {
      const image = element.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = (event) => {
        var image_encode = String(reader.result);
        if (image_encode) {
          var image_slice = image_encode.split(",")[1];
          rawImg.value = {
            file_name: element.target.files[0].name,
            file_data: image_slice,
          };
          console.log("helllo");
          console.log(image_slice);
        } else {
          rawImg.value = {
            file_name: element.target.files[0].name,
            file_data: reader.result,
          };
        }
      };
    }

    const setShapedata = async (data) => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_SHAPE_CATEGORY, db_data)
          .then(({ body }) => {
            shape.value = body.product;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const industry = ref([]);
    const sub = ref([]);
    const setIndustrydata = async (param) => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_INDUSTRY_CATEGORY, param)
          .then(({ body }) => {
            industry.value = body.industry_types_pc;
            //loading.value = false;
            console.log("sub.value");
            console.log(sub.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const form = ref([]);
    const setFormdata = async (param) => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_FORM_CATEGORY, param)
          .then(({ body }) => {
            form.value = body.form;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const input_type_tbl= ref([]);
    const setInputTabledata = async () => {
      
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_INPUT_TABLE_CAT_DATA)
          .then(({ data }) => {
            input_type_tbl.value = data;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const input_type_txt= ref([]);
    const setInputTextdata = async () => {
      
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_INPUT_TEXT_CAT_DATA)
          .then(({ data }) => {
            input_type_txt.value = data;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const length = ref([]);
    const setLengthdata = async (param) => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_Length_CATEGORY, param)
          .then(({ body }) => {
            length.value = body.length;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const parent = ref([]);
    const setParentData = async (data) => {
      // loading.value = true;
      try {
        var values = { search_term: data };
        if (values.search_term) {
          console.log("values");
          console.log(values);
          await store
            .dispatch(Actions.CUST_GET_CATEGORY, values)
            .then(({ data }) => {
              parent.value = data.page_data;
              loading.value = false;
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      } catch (e) {
        console.log(e);
      }
    };

    const tag = ref([]);
    const setTagData = async (data) => {
      //loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_GET_TAGLIST, values)
          .then(({ data }) => {
            tag.value = data.page_data;
            //loading.value = false;
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const display = ref([]);
    const setNextDisplayOrder = async () => {
      loading.value = true;
      try {
        var values = { category_id: ids.value, };
        await store
          .dispatch(Actions.CUST_GET_NEXT_DISPLAY_ORDER, values)
          .then(({ data }) => {
            formData.value.display_order = data.display_order;
            loading.value = false;
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };

    

    const addtag: any[] = [];
    const addTags = async (data) => {
      // for (let i=0; i<data.length; i++){
      //   const db_data = {
      //     "tag_name": data[i],
      //     "created_user_id": "1",
      //     }
      //     try {
      //       await axios.post(
      //         process.env.VUE_APP_CUSTOME_APP_API_URL+"product/create_tag",
      //         db_data,
      //       {
      //         headers: {
      //           "x-api-key": "BvFPhsxvLB5cTrNLbWdGC2aayThFkC6Z4iPcXeDh"
      //         },
      //       }
      //     ).then(({ data }) => {
      //       addtag.push(data.is_success)
      //       console.log(db_data);
      //       console.log(data);
      //     })
      //     .catch(({ response }) => {
      //       console.log(response);
      //     });
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }
    };

    const hsn = ref([]);
    const setHsnData = async (data) => {
      const db_data = {
        search_term: "",
      };
      loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_HSN_CATEGORY, values)
          .then(({ data }) => {
            hsn.value = data;
            console.log("HSN VALUE");
            console.log(hsn.value);
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };
    async function getCategoryTree() {
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_TREE)
        .then(({ data }) => {
          debugger;
          categoryTree.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    async function getParentAttrList() {
      let params = {
        category_id: ids.value,
      };
      await store
        .dispatch(Actions.CUST_GET_PARENT_ATTRIBUTE_LIST, params)
        .then(({ data }) => {
          debugger;
          parentAttributeList.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getUOM() {
      await store
        .dispatch(Actions.CUST_ADD_UOM_LIST)
        .then(({ data }) => {
          debugger;
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const getHsnData = async () => {
      // loadingHsn.value = true;
      // const db_data = { "search_term" : formData.value.hsn_select }
      // console.log("AAAAAAAAAAAAA")
      // console.log(db_data)
      // await store.dispatch(Actions.CUST_HSN_CATEGORY, db_data).then(({ data }) => {
      //     console.log(data);
      //   if(data){
      //     formData.value.hsn_code = data['hsn_code']
      //     formData.value.hsn_description = data['hsn_description']
      //     loadingHsn.value = false;
      //   }else{
      //     formData.value.hsn_description = ""
      //     Swal.fire({
      //       text: "Sorry, It's Invalid HSN!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     loadingHsn.value = false;
      //   }
      // })
      // .catch(({ response }) => {
      //   Swal.fire({
      //       text: "Sorry, It's Invalid Pincode!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     loadingHsn.value = false;
      // });
    };
    const formData = ref({
      name: "",
      value_required: "",
      value_type_select: "",
      min_value: "",
      max_value: "",
      input_table_select: "",
      input_text_select: "",
      //   is_child_select: "",
      parent_attr_setting_select: "",
      delimiter: "",
      display_order: "",
      is_search_required_yn: 0,
      uom_select: "",
      //   active_select: "",
    });
    
    const rules = ref({
      name: [
        {
          required: true,
          message: "Category Name is required",
          trigger: "change",
          //pattern:"^[a-zA-Z]+$",
        },
      ],
      value_required: [
        {
          required: true,
          message: "User Input Required is required",
          trigger: "change",
          //pattern:"^[a-zA-Z]+$",
        },
      ],
      value_type_select: [
        {
          required: true,
          message: "Value Type is required",
          trigger: "change",
        },
      ],

      min_value: [
        {
          required: true,
          message: "Min Value is required",
          trigger: "change",
        },
      ],
      // display_order: [
      //   {
      //     required: true,
      //     message: "Display Order is required",
      //     trigger: "change",
      //     max: 2,
      //   },
      // ],
      // is_search_required_yn: [
      //   {
      //     required: true,
      //     message: "Is Search Required is required",
      //     trigger: "change",
      //   },
      // ],
      max_value: [
        {
          required: true,
          message: "Max Value is required",
          trigger: "change",
        },
      ],
      //   is_child_select: [
      //     {
      //       required: true,
      //       message: "Is Child is required",
      //       trigger: "change",
      //     },
      //   ],
      //   parent_attr_setting_select: [
      //     {
      //       required: true,
      //       message: "Parrent Attribute is required",
      //       trigger: "change",
      //     },
      //   ],
      // delimiter: [
      //   {
      //     required: true,
      //     message: "Separator is required",
      //     trigger: "change",
      //     max: 1,
      //   },
      // ],
      // uom_select: [
      //   {
      //     required: true,
      //     message: "UOM is required",
      //     trigger: "change",
      //   },
      // ],
    });

    const setAttrSettingData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      console.log('dataaaaaaaaaaaaaaaa')
      console.log(data)
      const db_data = {
        category_attribute_setting_id: 0,
        category_id: ids.value,
        attribute_title: data.name,
        input_value_required_yn: data.value_required,
        input_value_type:
          data.value_required === 1 ? data.value_type_select : "",
        min_value:
          data.value_required === 1 && data.min_value
            ? parseFloat(data.min_value)
            : 0.0,
        max_value:
          data.value_required === 1 &&
          data.value_type_select === "RANGE" &&
          data.max_value
            ? parseFloat(data.max_value)
            : 0.0,
        parent_category_attribute_setting_id: data.parent_attr_setting_select,
        delimiter: data.parent_attr_setting_select != "" ? "" : data.delimiter,
        uom_id: data.parent_attr_setting_select != "" ? 0 : data.uom_select,
        is_search_required_yn: data.is_search_required_yn,
        display_order: data.display_order || 1,
        attribute_table_id : data.input_table_select || 0,
        user_id: user.user_id,
        active: 1,
      };

      await store
        .dispatch(Actions.CUST_ADD_ATTRIBUTE_SETTING, db_data)
        .then(({ data }) => {
          debugger;
          if (data.is_success) {
            // UploadImage(data.category_id);
            setTimeout(() => {
              loading.value = false;
              hideModal(addCategoryAttrSettingModalRef.value);
              Swal.fire({
                text: "Attribute Setting has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addCategoryAttrSettingModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setAttrSettingData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });

      // formRef.value.validate((valid) => {
      //   if (valid) {
      //     loading.value = true;

      //     setTimeout(() => {
      //       loading.value = false;

      //       Swal.fire({
      //         text: "Company has been successfully created.",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //       }).then(() => {
      //         hideModal(addCompanyModalRef.value);
      //         router.go(0)
      //       });
      //     }, 2000);
      //   } else {
      //     Swal.fire({
      //       text: "Sorry, looks like there are some errors detected, please try again.",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     return false;
      //   }
      // });
    };
    interface Tree {
      category_name: string;
      category_id: string;
    }
    const handleCheckChange = (
      data: Tree,
      checked: boolean,
      indeterminate: boolean
    ) => {
      debugger;
      if (checked) {
        treeRef.value?.setCheckedKeys([data.category_id], true);
        newTreeValue.value = [data.category_id];
        formData.value["parent_select"] = data.category_id;
        newTreeNode.value = data;
      } else {
        if (data.category_id === newTreeValue.value[0]) {
          treeRef.value?.setCheckedKeys([], false);
          newTreeValue.value = [];
          formData.value["parent_select"] = "";
          newTreeNode.value = data;
        } else {
          treeRef.value?.setCheckedKeys(newTreeValue.value, false);
          formData.value["parent_select"] = newTreeValue.value[0]
            ? newTreeValue.value[0]
            : "";
        }
      }
      console.log(data, checked, indeterminate);
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addCategoryAttrSettingModalRef,
      setFormdata,
      setLengthdata,
      setShapedata,
      setIndustrydata,
      setParentData,
      setHsnData,
      setTagData,
      getHsnData,
      addTags,
      encodeImagetoBase64L,
      active_list_data,
      form,
      length,
      rawImg,
      addtag,
      shape,
      level,
      tag,
      industry,
      parent,
      hsn,
      sub,
      categoryTree,
      getCategoryTree,
      getParentAttrList,
      getUOM,
      treeProps,
      handleCheckChange,
      treeRef,
      newTreeValue,
      newTreeNode,
      selection,
      valueTypes,
      parentAttributeList,
      setInputTabledata,
      input_type_tbl,
      uom_data,
      setInputTextdata,
      input_type_txt,
      setNextDisplayOrder,
      display,
    };
  },
});
