
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddProductGradeModal from "@/components/modals/forms/AddProductGradeModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import AddCategoryStandard from "@/components/modals/forms/AddCategoryStandard.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  props: {
    id: { required: true },
  },
  name: "price-listing",
  components: {
    AddCategoryStandard,
  },

  setup(props) {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const searchTearm = ref("");

    var ids = ref(props.id);
    interface ViewPrice {
      standard_attribute_groupe_name;
      standard_no;
      active: {
        label: string;
        color: string;
      };
    }

    var paginationData = ref({});
    var resultsView1 = ref<Array<ViewPrice>>([]);
    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getStandardList();
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getStandardList();
    };

    const std_modal = (category_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_product_cat_standard")
      );
      modal.show();
      (document.querySelector("#cat_id") as HTMLInputElement).value =
        category_id;
    };

    var tableData = ref<Array<ViewPrice>>([]);

    const getStandardList = async () => {
      try {
        var values = {
          // category_standard_id: 0,
          category_id: Number(props.id),
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
          search_term: "",
        };

        await store
          .dispatch(Actions.CUST_CAT_STANDARD_LIST, values)
          .then(({ data }) => {
            tableData.value = [];

            // set pagination
            paginationData.value["total"] = data.total_records;
            paginationData.value["start"] = data.records_from;
            paginationData.value["end"] = data.records_upto;
            paginationData.value["activePage"] = data.page;
            paginationData.value["totPage"] = data.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data.bar_range_start;
              index < data.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination

            var resultsM = ref<Array<ViewPrice>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";
            for (let j = 0; j < data.result_list.length; j++) {
              if (data.result_list[j].active) {
                active_label = "Yes";
                active_color = "success";
              } else {
                active_label = "No";
                active_color = "danger";
              }

              resultsView1.value = Array({
                standard_attribute_groupe_name:
                  data.result_list[j].standard_attribute_groupe_name,
                standard_no: data.result_list[j].standard_no,
                active: {
                  label: active_label,
                  color: active_color,
                },
              });

              tableData.value.splice(
                j,
                resultsView1.value.length,
                ...resultsView1.value
              );
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    onUpdated(() => {
      // console.log("aaap");
      // console.log(list_data);
    });

    onMounted(async () => {
      await getStandardList();
    });

    return {
      tableData,
      checkedCompany,
      resultsView1,
      getStandardList,
      changePageChange,
      refreshData,
      std_modal,
      ids,
      count,
      paginationData,
      loadingData,
    };
  },
});
