
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddProductGradeModal from "@/components/modals/forms/AddProductGradeModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter, useRoute } from "vue-router";
import { Modal } from "bootstrap";

export default defineComponent({
  props: {
    id: { required: true },
  },
  name: "inventory-listing",
  components: {},

  setup(props) {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const searchTearm = ref("");
    const router = useRouter();
    const route = useRoute();
    var ids = ref(props.id);
    interface ViewPrice {
      capability_matrix_id;
      manufacturer_company_id;
      manufacturer_company_name;
      plant_id;
      plant_name;
      brand_id;
      brand_name;
      brand_short_name;
    }

    var paginationData = ref({});
    var resultsView1 = ref<Array<ViewPrice>>([]);
    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const manufacturer_modal = async (cat_id) => {
      debugger;
      //   let routeData = router.resolve({
      //     // name: "apps-product-manufacturer-config",
      //     path: "/products/add-manufacturer-config/" + cat_id,
      //   });
      //   window.open(routeData.href, "_blank");
      //   router.push({ name: "apps-product-manufacturer-config" });
      router.push({ path: "/products/add-manufacturer-config/" + cat_id });
      //   const modal = new Modal(
      //     document.getElementById("kt_modal_manufacturer_config")
      //   );
      //   (document.querySelector("#cat_id") as HTMLInputElement).value = cat_id;
      //   modal.show();
    };

    var tableData = ref<Array<ViewPrice>>([]);

    const getPlantCapacityList = async () => {
      debugger;
      try {
        var values = {
          category_id: ids.value,
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
        };

        await store
          .dispatch(Actions.CUST_GET_PLANT_CAPACITY_LIST_DATA, values)
          .then(({ data }) => {
            tableData.value = [];

            // set pagination
            paginationData.value["total"] = data.total_records;
            paginationData.value["start"] = data.records_from;
            paginationData.value["end"] = data.records_upto;
            paginationData.value["activePage"] = data.page;
            paginationData.value["totPage"] = data.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data.bar_range_start;
              index < data.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination
            data = data.result_list;
            for (var j = 0; j < data.length; j++) {
              resultsView1.value = Array({
                capability_matrix_id: data[j].capability_matrix_id,
                manufacturer_company_id: data[j].manufacturer_company_id,
                manufacturer_company_name: data[j].manufacturer_company_name,
                plant_id: data[j].plant_id,
                plant_name: data[j].plant_name,
                brand_id: data[j].brand_id,
                brand_name: data[j].brand_name,
                brand_short_name: data[j].brand_short_name,
              });
              tableData.value.splice(
                j,
                resultsView1.value.length,
                ...resultsView1.value
              );
            }
            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getPlantCapacityList();
    };
    onUpdated(() => {
      // console.log("aaap");
      // console.log(list_data);
    });

    onMounted(async () => {
      await getPlantCapacityList();
    });

    return {
      tableData,
      checkedCompany,
      resultsView1,
      getPlantCapacityList,
      manufacturer_modal,
      ids,
      count,
      paginationData,
      loadingData,
      router,
      route,
      changePageChange,
    };
  },
});
