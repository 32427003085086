
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddProductGradeModal from "@/components/modals/forms/AddProductGradeModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import AddCategoryAttribute from "@/components/modals/forms/AddCategoryAttribute.vue";
import AddAttributeDynamicModal from "@/components/modals/forms/AddAttributeDynamicModal.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  props: {
    id: { required: true },
  },
  name: "price-listing",
  components: {
    // AddCategoryAttribute,
    AddAttributeDynamicModal,
  },

  setup(props) {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const structure_data = ref([]);
    // var table_data: any[] = [];
    const table_data = ref<any>([]);
    const table_cols_data = ref<any>([]);
    const UOM = ref("");
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const rules = ref({});
    const loadingData = ref<boolean>(true);
    const showAddtributeDialogue = ref<boolean>(false);
    const searchTearm = ref("");

    var ids = ref(props.id);
    interface ViewPrice {
      attribute_name;
      attribute_type;
    }

    interface formOutPut {
      category_attribute_setting_id: string;
      category_attribute_value: string;
    }

    var paginationData = ref({});
    var resultsView1 = ref<Array<ViewPrice>>([]);
    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const attr_modal = (category_id) => {
      debugger;
      const modal = new Modal(
        document.getElementById("kt_dynamic_modal_attribute")
      );
      modal.show();
      (document.querySelector("#cat_id") as HTMLInputElement).value =
        category_id;
    };

    var tableData = ref<Array<ViewPrice>>([]);

    const getProductPriceDetails = async () => {
      try {
        var values = { category_id: props.id };

        await store
          .dispatch(Actions.CUST_GET_ATTRIBUTE_LIST, values)
          .then(({ data }) => {
            tableData.value = [];
            for (var j = 0; j < data.attribute_list.length; j++) {
              //var attribute_type_list: any[] = []
              var attribute_val_list: any[] = [];
              for (
                var i = 0;
                i < data.attribute_list[j].attribute_data.length;
                i++
              ) {
                //attribute_type_list.push(data.attribute_list[j].attribute_data[i].attribute_type)
                attribute_val_list.push(
                  data.attribute_list[j].attribute_data[i].attribute_val
                );
              }
              resultsView1.value = Array({
                attribute_name: data.attribute_list[j].attribute_name,
                attribute_type: data.attribute_list[j].attribute_type,
                attribute_val: attribute_val_list,
              });
              tableData.value.splice(
                j,
                resultsView1.value.length,
                ...resultsView1.value
              );
            }
            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    onUpdated(() => {
      // console.log("aaap");
      // console.log(list_data);
    });

    const openDilogue = () => {
      getAttributeStructure();
      showAddtributeDialogue.value = true;
    };

    async function getAttributeStructure() {
      //trade type 1 for Supplier
      let values = {
        category_id: props.id,
      };
      await store
        .dispatch(Actions.CUST_GET_ATTRIBUTE_STRUCTURE_BY_CATEGORY_ID, values)
        .then(({ data }) => {
          debugger;
          UOM.value = data[0].uom_val;
          let formStructure = data.map((item) => {
            if (item.input_value_required_yn === 0) {
              let value = {};
              value["parentLabel"] = item.attribute_label;
              let childAttr = item.sub_attributes.map((child) => {
                let subAttr = {
                  input_value_type: child.input_value_type,
                  attribute_label: child.attribute_label,
                  category_attribute_setting_id:
                    child.category_attribute_setting_id,
                  category_attribute_value:
                    child.input_value_type === "RANGE" ? 0 : "",
                  attribute_name: child.attribute_label.toLowerCase(),
                };
                if (child.input_value_type === "RANGE") {
                  subAttr["range"] = {
                    min: parseFloat(child.min_value),
                    max: parseFloat(child.max_value),
                  };
                }

                return subAttr;
              });
              value["subAttr"] = childAttr;
              return value;
            } else {
              let value = {
                input_value_type: item.input_value_type,
                attribute_label: item.attribute_label,
                category_attribute_setting_id:
                  item.category_attribute_setting_id,
                category_attribute_value:
                  item.input_value_type === "RANGE" ? 0 : "",

                attribute_name: item.attribute_label.toLowerCase(),
              };
              value["parentLabel"] = "";
              if (item.input_value_type === "RANGE") {
                value["range"] = {
                  min: parseFloat(item.min_value),
                  max: parseFloat(item.max_value),
                };
              }
              return value;
            }
          });
          //      client_select: [
          //   {
          //     required: true,
          //     message: "Client is required",
          //     trigger: "change",
          //   },
          // ],
          structure_data.value = formStructure;
          let rule_stuctrue = {};
          formStructure.map((item) => {
            if (item.subAttr) {
              item.subAttr.map((attr) => {
                let label = attr.attribute_name;
                rule_stuctrue[label] = [
                  {
                    required: true,
                    message: attr.attribute_label + "  is required",
                    trigger: "change",
                  },
                ];
              });
            } else {
              let label = item.attribute_name;
              rule_stuctrue[label] = [
                {
                  required: true,
                  message: item.attribute_label + "  is required",
                  trigger: "change",
                },
              ];
            }
          });
          rules.value = rule_stuctrue;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const setCompanyData = async (data) => {
      debugger;
      let value_list = [
        {
          category_attribute_setting_id: 2,
          category_attribute_value: "1.80",
        },
      ];
      value_list.pop();
      data.map((item) => {
        console.log(item);
        if (item.subAttr) {
          item.subAttr.map((attr) => {
            value_list.push({
              category_attribute_setting_id: attr.category_attribute_setting_id,
              category_attribute_value: attr.category_attribute_value,
            });
          });
        } else {
          value_list.push({
            category_attribute_setting_id: item.category_attribute_setting_id,
            category_attribute_value: item.category_attribute_value,
          });
        }
      });
      let validateProductList = value_list.filter((item) => {
        return item.category_attribute_value != "";
      });
      if (
        validateProductList === [] ||
        validateProductList.length != value_list.length
      ) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Fill All required form fields.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      if (value_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Add At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      const db_data = {
        category_id: props.id,
        category_attributes: value_list,
      };
      console.log(JSON.stringify(db_data));
      try {
        await axios
          .post(
            // "https://services.mymetal.in/common_products/category_attributes_create",
            "https://services.mymetal.in/common_products/category/attributes_create",
            db_data,
            {
              headers: {
                "x-api-key": "BvFPhsxvLB5cTrNLbWdGC2aayThFkC6Z4iPcXeDh",
              },
            }
          )
          .then(({ data }) => {
            debugger;
            loading.value = false;
            console.log(data);
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                showAddtributeDialogue.value = false;
                getAttributeList(props.id);
              });
            }, 2000);
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(structure_data.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getAttributeList(props.id);
    };

    async function getAttributeList(id) {
      let params = {
        category_id: id,
        page: parseInt(paginationData.value["activePage"]),
        records_per_page: parseInt(paginationData.value["perPage"]),
      };

      await store
        .dispatch(Actions.CUST_GET_ATTRIBUTE_LIST_BY_CATEGORY_ID, params)
        .then(({ data }) => {
          debugger;
          table_cols_data.value = [];
          table_data.value = [];
          // set pagination
          paginationData.value["total"] = data.total_records;
          paginationData.value["start"] = data.records_from;
          paginationData.value["end"] = data.records_upto;
          paginationData.value["activePage"] = data.page;
          paginationData.value["totPage"] = data.total_pages;
          paginationData.value["pageRange"] = [];
          for (
            let index = data.bar_range_start;
            index < data.bar_range_end;
            index++
          ) {
            paginationData.value["pageRange"].push(index);
          }
          //endpagination
          data = data.result_list;
          data[0]?.category_attribute_values_nested_json.map((item) => {
            if (item.sub_attributes.length > 0) {
              table_cols_data.value.push(item.category_attribute_label);
              item.sub_attributes.map((col) => {
                table_cols_data.value.push(col.category_attribute_label);
              });
            } else {
              table_cols_data.value.push(item.category_attribute_label);
            }
          });
          // let table_rows: Record<string, string | number>[];
          // let table_rows = Record<string, string | number>[];
          data.map((item) => {
            let value = {};
            item.category_attribute_values_nested_json.map((col) => {
              if (col.sub_attributes.length > 0) {
                value[col.category_attribute_label] =
                  col.category_attribute_value;
                col.sub_attributes.map((attr) => {
                  value[attr.category_attribute_label] =
                    attr.category_attribute_value;
                });
              } else {
                value[col.category_attribute_label] =
                  col.category_attribute_value;
              }
            });
            table_data.value.push(value);
          });
          // table_data = table_rows;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    onMounted(async () => {
      await getProductPriceDetails();
      await getAttributeList(props.id);
    });

    return {
      tableData,
      checkedCompany,
      resultsView1,
      getProductPriceDetails,
      attr_modal,
      setCompanyData,
      ids,
      count,
      paginationData,
      showAddtributeDialogue,
      loadingData,
      structure_data,
      openDilogue,
      getAttributeList,
      table_cols_data,
      table_data,
      formRef,
      submit,
      loading,
      changePageChange,
    };
  },
});
