
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import { mask } from "vue-the-mask";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
import EditProductCategoriesModal from "@/components/modals/forms/EditProductCategoriesModal.vue";
import { Modal } from "bootstrap";
import { active } from "@/core/data/genericData";

export default defineComponent({
  name: "add-standard-categories-modal",
  directives: { mask },
  props: {
    id: { required: true, type: Object },
    // data: {
    //     type: Object
    // }
  },

  components: {},
  // created () {
  //       const field = document.querySelector("input[name=test]").value
  //       console.log(field)
  // }

  setup(props) {
    var ids = ref(props.id.ids);
    const formRef = ref<null | HTMLFormElement>(null);
    const addProductCatStandardModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const active_list_data = active;
    const loadingStandard = ref<boolean>(false);
    const router = useRouter();
    //var formData = ref({})
    const store = useStore();
    var mapping_list: any[] = [];
    const std = ref([]);
    //const inputValue = new Modal(document.getElementById("cat_id"));

    const formData = ref({
      category_id: "",
      std_select: [],
      group_select: "",
    });

    const std_dp = ref([]);
    const getStandardList = async (data) => {
      try {
        var values = { search_term: data };
        if (values.search_term) {
          await store
            .dispatch(Actions.CUST_CAT_STANDARD_DP, values)
            .then(({ data }) => {
              debugger;
              std_dp.value = data;
              // std_dp.value = data.page_data;
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      } catch (e) {
        console.log(e);
      }
    };

    const grp_dp = ref([]);
    const getGroupList = async (data) => {
      try {
        var values = { search_term: data };
        if (values.search_term) {
          await store
            .dispatch(Actions.CUST_CAT_GRP_DP, values)
            .then(({ data }) => {
              grp_dp.value = data;
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      } catch (e) {
        console.log(e);
      }
    };

    const setLinkData = async (standard_data) => {
      var user = JSON.parse(JwtService.getToken());

      const db_data = {
        category_id: ids.value,
        standard_id: standard_data.std_select,
      };

      await store
        .dispatch(Actions.CUST_ADD_STANDARD_CAT, db_data)
        .then(({ data }) => {
          debugger;
          if (data.is_success) {
            setTimeout(() => {
              loading.value = false;
              hideModal(addProductCatStandardModalRef.value);
              Swal.fire({
                text: "Product Category Standard mapped successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addProductCatStandardModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    onMounted(async () => {
      await getStandardList("");
      await getGroupList("");
    });

    const rules = ref({
      std_select: [
        {
          required: true,
          message: "Standard is required",
          trigger: "change",
        },
      ],
      group_select: [
        {
          required: true,
          message: "Group is required",
          trigger: "change",
        },
      ],
      active: [
        {
          required: true,
          message: "Required",
          trigger: "change",
        },
      ],
    });

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setLinkData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      std,
      getStandardList,
      getGroupList,
      grp_dp,
      std_dp,
      active_list_data,
      addProductCatStandardModalRef,
    };
  },
});
