
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddProductGradeModal from "@/components/modals/forms/AddProductGradeModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import JwtService from "@/core/services/JwtService";
import AddCategoryAttrSetting from "@/components/modals/forms/AddCategoryAttrSetting.vue";
import { Modal } from "bootstrap";
import { mask } from "vue-the-mask";
import { useRouter } from "vue-router";
import { string } from "yup";

export default defineComponent({
  props: {
    id: { required: true },
    data: {
        type: Object
    },
  },
  name: "price-listing",
   directives: { mask },
  components: {
    AddCategoryAttrSetting,
  },

  setup(props) {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const formRef = ref<null | HTMLFormElement>(null);
    const formRef1 = ref<null | HTMLFormElement>(null);
    const addValueModalRef = ref<null | HTMLElement>(null);
    const searchTearm = ref("");
    const loading = ref<boolean>(false);
    const router = useRouter();
    const formRefdelete = ref<null | HTMLFormElement>(null);
    var formDatadelete = ref({ delete_select: "",});
     var formDataView = ref({ valuetbl1: "",});
     var formDataViewValue = ref({ valuetbl2: "",});
    const editCategoryAttrSettingModalRef = ref<null | HTMLElement>(null);
    const deleteModalRef = ref<null | HTMLElement>(null);
    const uom_data = ref([]);
    var ids = ref(props.id);
    interface ViewPrice {
      //   standard_attribute_groupe_name;
      //   standard_no;
      category_attribute_setting_id: 1;
      category_id: 1;
      category_name: "Steel";
      attribute_label: "Dimension";
      attribute_label_search: "dimension";
      attribute_table_id: "";
      input_value_required_yn: 0;
      input_value_type: "";
      min_value: "0.00";
      max_value: "0.00";
      uom_id: 1;
      uom_val: "MT";
      delimiter: "x";
      active: {
        label: string;
        color: string;
      };
    }

    interface View {
      category_attribute_value: "",
    }

    interface ViewValue {
      category_attribute_value: "",
    }

    var formData1 = ref({
      input_value_select: "",
      value_select: "",
    });

    var paginationData = ref({});
    var resultsView1 = ref<Array<ViewPrice>>([]);
    var resultsView2 = ref<Array<View>>([]);
    var resultsView3 = ref<Array<ViewValue>>([]);
    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 10,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    var paginationData1 = ref({});

    paginationData1.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    var paginationData2 = ref({});

    paginationData2.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      
    };

     const refreshData1 = () => {
      paginationData1.value["activePage"] = 1;
      ViewData();
      console.log("REFRESH")
      
    };

    const changePageChange1 = (page) => {
      paginationData1.value["activePage"] = page;
     ViewData();
      console.log("REFRESHHHHHHHHHHHHHHH")
      
    };

    const refreshData2 = () => {
      paginationData2.value["activePage"] = 1;
      ViewValueData();
      console.log("REFRESH")
      
    };

    const changePageChange2 = (page) => {
      paginationData2.value["activePage"] = page;
     ViewValueData();
      console.log("REFRESHHHHHHHHHHHHHHH")
      
    };

    const std_modal = (category_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_product_cat_attr_setting")
      );
      modal.show();
      (document.querySelector("#cat_id") as HTMLInputElement).value =
        category_id;
    };

    const value_modal = (category_attribute_setting_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_add_value")
      );
      modal.show();
      (document.querySelector("#value_id") as HTMLInputElement).value =
        category_attribute_setting_id;
    };

    
    

    var tableData = ref<Array<ViewPrice>>([]);
    var tableData1 = ref<Array<View>>([]);
    var tableData2 = ref<Array<ViewValue>>([]);

    const getSettingList = async () => {
      try {
        var values = {
          search_term: "",
          category_id: Number(props.id),
          category_attribute_setting_id: 0,
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
          // records_per_page: parseInt(paginationData.value["perPage"]),
        };

        await store
          .dispatch(Actions.CUST_CAT_ATTRIBUTE_SETTING_LIST, values)
          .then(({ data }) => {
            tableData.value = [];

            // set pagination
            paginationData.value["total"] = data.total_records;
            paginationData.value["start"] = data.records_from;
            paginationData.value["end"] = data.records_upto;
            paginationData.value["activePage"] = data.page;
            paginationData.value["totPage"] = data.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data.bar_range_start;
              index < data.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination

            var resultsM = ref<Array<ViewPrice>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";
            for (let j = 0; j < data.result_list.length; j++) {
              if (data.result_list[j].active) {
                active_label = "Yes";
                active_color = "success";
              } else {
                active_label = "No";
                active_color = "danger";
              }

              resultsView1.value = Array({
                // standard_attribute_groupe_name:
                //   data.result_list[j].standard_attribute_groupe_name,
                // standard_no: data.result_list[j].standard_no,

                category_attribute_setting_id:
                  data.result_list[j].category_attribute_setting_id,
                category_id: data.result_list[j].category_id,
                category_name: data.result_list[j].category_name,
                attribute_label: data.result_list[j].attribute_label,
                attribute_label_search:
                  data.result_list[j].attribute_label_search,
                input_value_required_yn:
                  data.result_list[j].input_value_required_yn,
                input_value_type: data.result_list[j].input_value_type,
                min_value: data.result_list[j].min_value,
                max_value: data.result_list[j].max_value,
                uom_id: data.result_list[j].uom_id,
                uom_val: data.result_list[j].uom_val,
                delimiter: data.result_list[j].delimiter,
                attribute_table_id : data.result_list[j].attribute_table_title,
                parent_attribute_title: data.result_list[j].parent_attribute_title,
                is_search_required_yn: data.result_list[j].is_search_required_yn,
                display_order: data.result_list[j].display_order,
                active: {
                  label: active_label,
                  color: active_color,
                },
              });

              tableData.value.splice(
                j,
                resultsView1.value.length,
                ...resultsView1.value
              );
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    onUpdated(() => {
      // console.log("aaap");
      // console.log(list_data);
    });

    onMounted(async () => {
      await getSettingList();
      //await getParentAttrList();
      await setFormData(props.data);
      
      //await setInputTabledata();
      //await setInputTextdata(formData1.value['value_id']);
    });

    const input_type_tbl= ref([]);
    const setInputTabledata = async () => {
      
      loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_INPUT_TABLE_CAT_DATA)
          .then(({ data }) => {
            input_type_tbl.value = data;
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    // const input_type_txt= ref([]);
    // const setInputTextdata = async (value_data) => {
     
    //   try {
    //     var values = { "category_attribute_setting_id":(document.querySelector('#value_id') as HTMLInputElement).value, };
    //     await store
    //       .dispatch(Actions.CUST_GET_INPUT_TEXT_CAT_DATA, values)
    //       .then(({ data }) => {
            
    //         input_type_txt.value = data;
    //         //loading.value = false;
    //       })
    //       .catch(({ response }) => {
    //         console.log(response);
    //       });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // };

     async function getUOM() {
      await store
        .dispatch(Actions.CUST_ADD_UOM_LIST)
        .then(({ data }) => {
          //debugger;
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    var formData = ref({});
    const valueTypes = ref([
      {
        id: "RANGE",
        name: "Range",
      },
      {
        id: "TEXT",
        name: "Text",
      },
      {
        id: "NUMBER",
        name: "Number",
      },
      {
        id: "TABLE",
        name: "Table",
      },
    ]);

    const level = ref([
      {
        id: 0,
        name: "Level 1",
      },
      {
        id: 1,
        name: "Level 2",
      },
      {
        id: 2,
        name: "Level 3",
      },
      {
        id: 3,
        name: "Level 4",
      },
    ]);
    const selection = ref([
      {
        id: 0,
        name: "No",
      },
      {
        id: 1,
        name: "Yes",
      },
    ]);

    const rules = ref({
      name: [
        {
          required: true,
          message: "Category Name is required",
          trigger: "change",
          //pattern:"^[a-zA-Z]+$",
        },
      ],
      value_required: [
        {
          required: true,
          message: "User Input Required is required",
          trigger: "change",
          //pattern:"^[a-zA-Z]+$",
        },
      ],
      value_type_select: [
        {
          required: true,
          message: "Value Type is required",
          trigger: "change",
        },
      ],

      min_value: [
        {
          required: true,
          message: "Min Value is required",
          trigger: "change",
        },
      ],
      // display_order: [
      //   {
      //     required: true,
      //     message: "Display Order is required",
      //     trigger: "change",
      //     max: 2,
      //   },
      // ],
      // is_search_required_yn: [
      //   {
      //     required: true,
      //     message: "Is Search Required is required",
      //     trigger: "change",
      //   },
      // ],
      max_value: [
        {
          required: true,
          message: "Max Value is required",
          trigger: "change",
        },
      ],
      //   is_child_select: [
      //     {
      //       required: true,
      //       message: "Is Child is required",
      //       trigger: "change",
      //     },
      //   ],
      //   parent_attr_setting_select: [
      //     {
      //       required: true,
      //       message: "Parrent Attribute is required",
      //       trigger: "change",
      //     },
      //   ],
      // delimiter: [
      //   {
      //     required: true,
      //     message: "Separator is required",
      //     trigger: "change",
      //     max: 1,
      //   },
      // ],
      // uom_select: [
      //   {
      //     required: true,
      //     message: "UOM is required",
      //     trigger: "change",
      //   },
      // ],
    });

    const rules1 = ref({
      input_value_select: [
        {
          required: true,
          message: "Value is required",
          trigger: "change",
          
        },
      ],
    })

    const parentAttributeList = ref<any>([]);
    async function getParentAttrList() {
      let params = {
        category_id: ids.value,
      };
      await store
        .dispatch(Actions.CUST_GET_PARENT_ATTRIBUTE_LIST, params)
        .then(({ data }) => {
          //debugger;
          parentAttributeList.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    const parent_value = ref([]);
     const uom_value = ref([]);
     const table_value = ref([]);
     const setFormData = async (data) => {    
      data = data['category_attribute_setting_id'];
        try {

       var values = { 
          category_id: Number(props.id),
          category_attribute_setting_id: data,
          page: 1,
          records_per_page: 1,
          search_term: "",
            }
           
        await store.dispatch(Actions.CUST_GET_ATTRIBUTE_SETTING_LIST_BY_ID, values).then(({ data }) => {    
          parent_value.value = data.parent_category_attribute_setting_id,
          uom_value.value = data.uom_id
          table_value.value = data.attribute_table_id
          formData.value = {
            id : data.category_attribute_setting_id,
            value_required: data.input_value_required_yn,
            value_type_select: data.input_value_type,
            parent_attr_setting_select : data.parent_attribute_title,
            //parent_attr_setting_title: data.parent_attribute_title,
            is_search_required_yn: data.is_search_required_yn,
            uom_select: data.uom_val,
            //uomvalue : data.uom_val,
            min_value: data.min_value,
            max_value: data.max_value,
            delimiter: data.delimiter,
            name: data.attribute_label,
            display_order: data.display_order,
            input_table_select: data.attribute_table_title,
            
            };
            console.log("formdataaaaaaaaaaa")
          console.log(formData.value)
          })
          
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }
      const defaultvalue = ref()
      const defaultvalue2 = ref()
      const defaultvalue3 = ref()
     const setLinkData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      
      var standard_id = ids.value;
      
     if(data.parent_attr_setting_select !== '' && (typeof(data.parent_attr_setting_select) == 'string')){
      defaultvalue.value = parent_value.value 
     }

     if(data.parent_attr_setting_select !== '' && (typeof(data.parent_attr_setting_select) == 'number')){
      defaultvalue.value = data.parent_attr_setting_select 
     }

     if(data.parent_attr_setting_select == '' || data.parent_attr_setting_select == 'None'){
      defaultvalue.value = 0
     }

     if(data.uom_select !== '' && (typeof(data.uom_select) == 'string')){
      defaultvalue2.value = uom_value.value
     }

     if(data.uom_select !== '' && (typeof(data.uom_select) == 'number')){
      defaultvalue2.value = data.uom_select
     }

     if(data.uom_select == '' || data.uom_select == 'None'){
      defaultvalue2.value = 0
     }
     
     if(data.parent_attr_setting_select !== '' || data.parent_attr_setting_select !== 'None'){
      defaultvalue2.value = 0
      defaultvalue3.value = 0
     }

     if(data.parent_attr_setting_select == '' || data.parent_attr_setting_select == 'None'){
      defaultvalue2.value = data.uom_select
     }

     if((data.parent_attr_setting_select == '' || data.parent_attr_setting_select == 'None') && (typeof(data.uom_select) == 'string') ){
      defaultvalue2.value = uom_value.value
     }

       const db_data = {
        category_attribute_setting_id: data.id,
        category_id: Number(props.id),
        attribute_title: data.name,
        input_value_required_yn: data.value_required,
        input_value_type:
          data.value_required === 1 ? data.value_type_select : "",
        min_value:
          data.value_required === 1 && data.min_value
            ? parseFloat(data.min_value)
            : 0.0,
        max_value:
          data.value_required === 1 &&
          data.value_type_select === "RANGE" &&
          data.max_value
            ? parseFloat(data.max_value)
            : 0.0,
            delimiter: 
        (data.parent_attr_setting_select == "") || (data.parent_attr_setting_select == 'None') ? data.delimiter : "",

        parent_category_attribute_setting_id: defaultvalue.value,
        uom_id: defaultvalue2.value || defaultvalue3.value,
        is_search_required_yn: data.is_search_required_yn,
        display_order: data.display_order || 1,
        attribute_table_id : table_value.value,
        user_id: user.user_id,
        active: 1,
      };
      
      await store
        .dispatch(Actions.CUST_UPDATE_ATTRIBUTE_SETTING, db_data).then(({ data }) => {

          if(data.is_success)
         {            
          setTimeout(() => {
              loading.value = false;
              hideModal(editCategoryAttrSettingModalRef.value);
              Swal.fire({
                text: "Category Attribute Settings Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editCategoryAttrSettingModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const setAddData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      // data = data['category_attribute_setting_id'];
      
      const db_data = {
         "category_attribute_value": data.input_value_select,
        "category_attribute_setting_id": (document.querySelector('#value_id') as HTMLInputElement).value,
         "user_id": user.user_id,
         "active":1
      };
     
      await store
        .dispatch(Actions.CUST_ADD_CAT_ATTR_SETTINGS_VALUE, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              loading.value = false;
              hideModal(addValueModalRef.value);
              Swal.fire({
                text: "Record Added Successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addValueModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }

     const delete_modal = (category_attribute_setting_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_delete")
      );
      modal.show();
      (document.querySelector("#delete_id") as HTMLInputElement).value =
        category_attribute_setting_id;
      
    };


     const DeleteData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      // data = data['category_attribute_setting_id'];
      
      const db_data = {
         "category_id" : Number(props.id),
         "category_attribute_setting_id": (document.querySelector('#delete_id') as HTMLInputElement).value,
         "user_id": user.user_id,
      };

      console.log("DELLLLLLLLLLLLLLLLLLLLL")
      console.log(db_data)

      await store
        .dispatch(Actions.CUST_DELETE_CAT_ATTR_SETTINGS, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Record Deleted Successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;
            // hideModal(deleteModalRef.value)
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }

    const ViewData = async () => {
      //data = data['category_attribute_setting_id'];
      try{
      const db_data = {
         "page":parseInt(paginationData1.value["activePage"]),
          "records_per_page":parseInt(paginationData1.value["perPage"]),
         "category_attribute_setting_id": (document.querySelector("#value_tbl1") as HTMLInputElement).value,
      };
      console.log("ViewData")
      console.log(db_data)
      
      await store
        .dispatch(Actions.CUST_GET_ATTRIBUTE_VALUE_SETTING_LIST_BY_ID, db_data)
        .then(({ data }) => {
          tableData1.value = [];
          // set pagination
            paginationData1.value["total"] = data.total_records;
            paginationData1.value["start"] = data.records_from;
            paginationData1.value["end"] = data.records_upto;
            paginationData1.value["activePage"] = data.page;
            paginationData1.value["totPage"] = data.total_pages;
            paginationData1.value["pageRange"] = [];
            for (
              let index = data.bar_range_start;
              index < data.bar_range_end;
              index++
            ) {
              paginationData1.value["pageRange"].push(index);
            }
            //endpagination
          for (let j = 0; j < data.result_list.length; j++) {
                resultsView2.value = Array({
                category_attribute_value : data.result_list[j].category_attribute_value,
                
              });

              tableData1.value.splice(j, resultsView2.value.length, ...resultsView2.value);
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData1.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    const view_modal = (category_attribute_setting_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_product_view_attr_setting")
      );
      modal.show();
      (document.querySelector("#value_tbl1") as HTMLInputElement).value =
        category_attribute_setting_id;
      ViewData();
    };
    
    const ViewValueData = async () => {
      //data = data['category_attribute_setting_id'];
      try{
      const db_data = {
         "page":parseInt(paginationData2.value["activePage"]),
          "records_per_page":parseInt(paginationData2.value["perPage"]),
         "category_attribute_setting_id": (document.querySelector("#value_tbl2") as HTMLInputElement).value,
      };
      console.log('db_data')
      console.log(db_data)

      await store
        .dispatch(Actions.CUST_GET_INPUT_TEXT_CAT_DATA, db_data)
        .then(({ data }) => {
          console.log(data)
          tableData2.value = [];
          // set pagination
            paginationData2.value["total"] = data.total_records;
            paginationData2.value["start"] = data.records_from;
            paginationData2.value["end"] = data.records_upto;
            paginationData2.value["activePage"] = data.page;
            paginationData2.value["totPage"] = data.total_pages;
            paginationData2.value["pageRange"] = [];
            for (
              let index = data.bar_range_start;
              index < data.bar_range_end;
              index++
            ) {
              paginationData2.value["pageRange"].push(index);
            }
            //endpagination
          for (let j = 0; j < data.result_list.length; j++) {
                resultsView3.value = Array({
                category_attribute_value : data.result_list[j].category_attribute_value,
              });

              tableData2.value.splice(j, resultsView3.value.length, ...resultsView3.value);
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData2.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    const view_value_modal = (category_attribute_setting_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_product_view_attr_setting_value")
      );
      modal.show();
      (document.querySelector("#value_tbl2") as HTMLInputElement).value =
        category_attribute_setting_id;
      ViewValueData();
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setLinkData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const addsubmit = async () => {
      loading.value = true;

      if (!formRef1.value) {
        loading.value = false;
        return;
      }

      formRef1.value.validate(async (valid) => {
        if (valid) {
          await setAddData(formData1.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const delete_submit = async () => {
      loading.value = true;

      if (!formRefdelete.value) {
        loading.value = false;
        return;
      }

      formRefdelete.value.validate(async (valid) => {
        if (valid) {
          await DeleteData(formDatadelete.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      tableData,
      tableData1,
      tableData2,
      formRef,
      formData,
      formRef1,
      formData1,
      rules,
      submit,
      rules1,
      addsubmit,
      formDatadelete,
      delete_submit,
      formRefdelete,
      checkedCompany,
      resultsView1,
      resultsView2,
      resultsView3,
      getSettingList,
      ViewData,
      ViewValueData,
      changePageChange,
      refreshData,
      refreshData1,
      changePageChange1,
      refreshData2,
       changePageChange2,
      setFormData,
      DeleteData,
      setAddData,
      std_modal,
      value_modal,
      view_modal,
      delete_modal,
      ids,
      count,
      paginationData,
      paginationData1,
      paginationData2,
      loadingData,
      loading,
      uom_data,
      valueTypes,
      selection,
      level,
      deleteModalRef,
      parentAttributeList,
      getParentAttrList,
      getUOM,
      editCategoryAttrSettingModalRef,
      addValueModalRef,
      setInputTabledata,
      //setInputTextdata,
      input_type_tbl,
      //input_type_txt,
      formDataView,
      formDataViewValue,
      view_value_modal,
    };
  },
});
